import './App.css';
import React from 'react';
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';

function App() {
  return (
    <Router>
      <header className="haeder">
        <div className="logo">Logo</div>
        <nav>
          <Link to="/projects">Projects</Link>
          <Link to="/articles">Articles</Link>
        </nav>
        <button className="login-btn">login</button>
      </header>
      <main>
        <Routes>
          <Route path="/projects" element={<h1>projs</h1>} />
          <Route path="/articles" element={<h1>arts</h1>} />
          <Route path="/" element={<h1>main</h1>} />
        </Routes>
      </main>
    </Router>
  );
}

export default App;
